<template>
  <Loading v-if="initLoading" />
  <div v-else class="luckDraw">
    <Header v-if="!$route.query.platform">惊喜抽奖</Header>
    <div class="container" :style="{ top: $route.query.platform ? 0 : '50px' }">
      <div class="header" v-if="token">
        <div class="left">
          <img :src="userInfo.headImg || headImg" alt="" />
          <span>{{ userInfo.nickName }}</span>
        </div>
        <div class="right" @click="getLotteryUserLog">中奖记录</div>
      </div>
      <LuckyGrid
        style="margin: 15px auto 0"
        ref="myLucky"
        width="9.2rem"
        height="8.2rem"
        :rows="3"
        :cols="4"
        :prizes="prizes"
        :blocks="blocks"
        :buttons="buttons"
        :defaultConfig="defaultConfig"
      />

      <!-- 列表 -->
      <div class="list">
        <div class="head">
          <div class="line">
            <img src="../../assets/images/line.png" alt="" />
          </div>
          <div class="middle">做任务得抽奖机会</div>
          <div class="line">
            <img src="../../assets/images/line.png" alt="" />
          </div>
          <div class="right" @click="showPopUp = true">
            <img src="../../../static/image/doubt1.png" alt="" />
          </div>
        </div>
        <!-- <div class="listItem">
          <div class="listLeft">至少为一个心仪球队打CALL，抽奖机会+1</div>
          <div class="listRight" @click="goTo('/lickTeam')">去投票</div>
        </div>
        <div class="listItem">
          <div class="listLeft">至少参与一次世界杯竞猜，抽奖机会+1</div>
          <div class="listRight" @click="goTo('/guessing')">去竞猜</div>
        </div> -->
        <div class="listItem" v-for="item in lotteryTaskList" :key="item.id">
          <div class="listLeft">{{ item.remark }}</div>
          <div
            class="listRight"
            v-if="item.type === 0 && item.haveNum == 0"
            @click="initSdBridge(item)"
          >
            去签到
          </div>
          <div
            class="listRight"
            v-else-if="item.type === 1 && item.haveNum == 0"
            @click="initSdBridge(item)"
          >
            去购买
          </div>
          <div
            class="listRight"
            v-else-if="item.type === 2 && item.haveNum == 0"
            @click="initSdBridge(item)"
          >
            去购买
          </div>
          <div
            class="listRight"
            v-else-if="item.type === 3"
            @click="initSdBridge(item)"
          >
            {{
              item.invitationNum > item.peopleNum
                ? item.peopleNum
                : item.invitationNum
            }}/{{ item.peopleNum }}
          </div>
          <div
            class="listRight"
            v-else-if="item.type === 4 && item.isComplete == 0"
            @click="initSdBridge(item)"
          >
            去合成
          </div>
          <div
            class="listRight"
            v-else-if="item.type === 5 && item.haveNum == 0"
            @click="initSdBridge(item)"
          >
            去交易
          </div>
          <div
            class="listRight"
            v-else-if="item.type === 7 && item.isComplete == 0"
            @click="initSdBridge(item)"
          >
            去投票
          </div>
          <div
            class="listRight"
            v-else-if="item.type === 6 && item.isComplete == 0"
            @click="initSdBridge(item)"
          >
            去竞猜
          </div>
          <div
            class="listRight"
            v-else-if="item.type === 8 && item.isComplete == 0"
            @click="initSdBridge(item)"
          >
            去兑换
          </div>
          <div
            class="listRight"
            v-else-if="item.type === 9 && item.isComplete == 0"
            @click="initSdBridge({ type: 9 })"
          >
            看视频
          </div>
          <div class="listRight defi" v-else>已完成</div>
        </div>
      </div>
    </div>

    <div class="box" v-if="showBox !== 0">
      <!-- 中奖弹框 -->
      <!-- 盲盒系列 -->
      <div class="boxItem" v-if="showBox === 3">
        <div class="title">{{ lotteryInfo.albumName }}</div>
        <div class="img">
          <img :src="lotteryInfo.pic" alt="" />
          <!-- <div class="tag">
            <img src="../../assets/images/openBox.png" alt="" />
          </div> -->
        </div>
        <div class="btn2" @click="showBox = 1"><div>拆开看看</div></div>
      </div>
      <!-- 拆盲盒 -->
      <div
        class="boxItem"
        v-if="showBox === 1 || showBox === 6 || showBox === 2"
      >
        <div v-if="lotteryInfo.jaozo && lotteryInfo.jaozo == 1" class="title">
          {{ lotteryInfo.remark }}*{{ lotteryInfo.number }}
        </div>
        <div class="img" v-if="showBox === 6">
          <img
            v-if="$route.query.platform"
            src="../../../static/luck/prize1.png"
            alt=""
          />
          <img v-else src="../../../static/luck/prize.png" alt="" />
        </div>
        <div class="img" v-else-if="showBox === 2">
          <img src="../../../static/luck/zqq.png" alt="" />
        </div>
        <div class="img" v-else>
          <img
            :src="
              lotteryInfo.type === 3 ? lotteryInfo.goodsPic : lotteryInfo.pic
            "
            alt=""
          />
        </div>
        <div class="btn" v-if="showBox === 6 && $route.query.platform">
          <div @click="initSdBridge({ type: 10 })">去看视频</div>
        </div>
        <div class="btn" v-else-if="lotteryNum">
          <div @click="startCallback">再抽一次</div>
        </div>
        <div class="close" @click="showBox = 0"></div>
      </div>
      <!-- 福袋 -->
      <div class="boxItem" v-if="showBox === 4">
        <div class="blessing">
          <img src="../../assets/images/blessingBag.png" alt="" />
          <div class="btn1" @click="showBox = 7"><div>拆开看看</div></div>
        </div>
      </div>
      <!-- 再抽一次 -->
      <div class="boxItem" v-if="showBox === 5">
        <div class="redEnvelopes">
          <img src="../../../static/image/sqzh.png" alt="" />
        </div>
        <div class="btn" v-if="lotteryNum" @click="startCallback">
          <div>再抽一次</div>
        </div>
        <div class="close" @click="showBox = 0"></div>
      </div>
      <!-- 拆福袋 -->
      <div class="boxItem" v-if="showBox === 7">
        <div class="redEnvelopes">
          <img src="../../assets/images/redEnvelopes.png" alt="" />
          <div class="content">
            <div class="title">恭喜您获得现金</div>
            <div class="num">
              ¥ <span>{{ lotteryInfo.fee }}</span>
            </div>
          </div>
        </div>
        <div class="btn" v-if="lotteryNum" @click="startCallback">
          <div>再抽一次</div>
        </div>
        <div class="close" @click="showBox = 0"></div>
      </div>
      <!-- 中奖记录 -->
      <div class="boxItem" v-if="showBox === 8">
        <div class="winningrecord">
          <div class="winContainer">
            <div class="title">中奖记录</div>
            <div class="content" v-if="lotteryUserLogList.length">
              <div
                class="listItem"
                v-for="item in lotteryUserLogList"
                :key="item.id"
              >
                <div class="listLeft">{{ item.remark }}</div>
                <div class="listRight">{{ item.updated_at }}</div>
              </div>
            </div>
            <div class="def" v-else>
              <img src="../../../static/image/hezi2.png" alt="" />
              <p>暂无数据</p>
            </div>
          </div>
        </div>
        <div class="close" @click="showBox = 0"></div>
      </div>
    </div>

    <!-- 去签到 -->
    <div class="selectPayType" v-if="isShowSign">
      <div class="selectPayTypeBox"></div>
      <div class="bg">
        <div class="content">请在APP内完成签到</div>
        <div class="btnGroup">
          <div class="unCompleted" @click="isShowSign = false">取消</div>
          <div class="completed" @click="openApp">去下载app</div>
        </div>
      </div>
    </div>

    <!-- 规则 -->
    <van-popup v-model:show="showPopUp" class="popUp">
      <div class="header">抽奖规则</div>
      <div class="content" v-html="applyrule"></div>
      <div class="bottom" @click="showPopUp = false">我知道了</div>
    </van-popup>

    <!-- 分享 -->
    <div class="box" v-if="showPoster" @click="showPoster = false">
      <div>
        <img :src="posterUrl" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import boxBg from "../../assets/images/boxBg.png";
import active from "../../assets/images/active.png";
import bg from "../../assets/images/bg.png";
import btn from "../../assets/images/btn.png";
import { addBridgeForAndroidWebView } from "sdbridge-android";
import loading from "../../components/loading/loading.vue";
import headImg from "@/assets/images/touxiang.png"; //默认头像
const imgs = {
  src: boxBg,
  width: "100%",
  height: "100%",
  activeSrc: active,
};
export default {
  components: { loading },
  data() {
    return {
      showPopUp: false,
      userInfo: {},
      headImg,
      showPoster: false,
      posterUrl: "",
      token: null,
      isShowSign: false,
      lotteryNum: 0,
      query: {
        pageindex: 1,
        pagesize: 1000,
      },
      lotteryUserLogList: [],
      blocks: [
        {
          padding: "1.5rem",
          imgs: [{ src: bg, width: "100%", height: "100%" }],
        },
      ],
      lotteryInfo: {},
      initLoading: true,
      defaultConfig: {
        gutter: 5,
      },
      prizes: [
        {
          x: 0,
          y: 0,
          imgs: [{ ...imgs }],
          background: "transparent",
          borderRadius: "0",
        },
        {
          x: 1,
          y: 0,
          imgs: [{ ...imgs }],
          background: "transparent",
          borderRadius: "0",
        },
        {
          x: 2,
          y: 0,
          imgs: [{ ...imgs }],
          background: "transparent",
          borderRadius: "0",
        },
        {
          x: 3,
          y: 0,
          imgs: [{ ...imgs }],
          background: "transparent",
          borderRadius: "0",
        },
        {
          x: 3,
          y: 1,
          imgs: [{ ...imgs }],
          background: "transparent",
          borderRadius: "0",
        },
        {
          x: 3,
          y: 2,
          imgs: [{ ...imgs }],
          background: "transparent",
          borderRadius: "0",
        },
        {
          x: 2,
          y: 2,
          imgs: [{ ...imgs }],
          background: "transparent",
          borderRadius: "0",
        },
        {
          x: 1,
          y: 2,
          imgs: [{ ...imgs }],
          background: "transparent",
          borderRadius: "0",
        },
        {
          x: 0,
          y: 2,
          imgs: [{ ...imgs }],
          background: "transparent",
          borderRadius: "0",
        },
        {
          x: 0,
          y: 1,
          imgs: [{ ...imgs }],
          background: "transparent",
          borderRadius: "0",
        },
      ],
      buttons: [
        {
          x: 1,
          y: 1,
          col: 2,
          background: "transparent",
          imgs: [
            {
              src: btn,
              width: "2.7rem",
              height: "1.17rem",
            },
          ],
          callback: this.startCallback,
        },
        {
          x: 1,
          y: 1,
          col: 2,
          background: "transparent",
          fonts: [
            {
              text: "剩余抽奖机会：0",
              fontSize: "0.3rem",
              fontColor: "#fff",
              top: "1.28rem",
            },
          ],
        },
      ],
      showBox: 0,
      bridge: null,
      lotteryList: [],
      lotteryTaskList: [],
      luckDraw: false,
      isAndroid:
        navigator.userAgent.indexOf("Android") > -1 ||
        navigator.userAgent.indexOf("Adr") > -1,
      isiOS: !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
    };
  },
  computed: {
    applyrule() {
      return this.$store.state.anyInfo.luckrule;
    },
  },
  watch: {
    showBox(val) {
      if (val == 0) {
        if (this.$refs.myLucky) {
          this.$refs.myLucky.init();
        }
      }
    },
  },
  async mounted() {
    console.log(process.env);
    this.token = this.$route.query.token;
    if (!this.applyrule) {
      this.$api.anyInfo().then((res) => {
        if (res.code == 0) {
          this.$store.commit("SET_ANYINFO", res.data);
        }
      });
    }
    let bridge = window.WebViewJavascriptBridge;
    if (!bridge) {
      console.log("TypeScript正在尝试挂载");
      addBridgeForAndroidWebView();
      //@ts-ignore
      bridge = window.WebViewJavascriptBridge;
      if (bridge) {
        console.log(
          "window.WebViewJavascriptBridge, 已经被TypeScript挂载成功!"
        );
        this.bridge = bridge;
      }
    } else {
      this.bridge = bridge;
      console.log("window.WebViewJavascriptBridge 挂载成功！！");
    }
    if (this.token) {
      this.getUserInfo();
    }
    this.getLotteryList();
    this.getLotteryTaskList();

    const that = this;
    window.getLuckDrawNum = () => {
      console.log("调用getLuckDrawNum");
      that.getLotteryList();
      this.getLotteryTaskList();
    };
  },
  methods: {
    goTo(url) {
      if (this.$route.query.platform) {
        this.$router.push(
          `${url}?token=${this.$route.query.token}&platform=${this.$route.query.platform}&from=h5`
        );
      } else {
        this.$router.push(`${url}?token=${this.$route.query.token}`);
      }
    },
    getUserInfo() {
      this.$api.anyUserinfo({ token: this.token }).then((res) => {
        if (res.code == 0) {
          this.userInfo = res.data;
        }
      });
    },
    openApp() {
      window.open("https://v.hxnft.shop/appDownload/index.html");
      this.isShowSign = false;
      // if (this.isAndroid) {
      //   window.open('scheme://huaxiashuyi:8888/openApp', '_self');
      // } else if (this.isiOS) {
      //   window.open('TounarInformationHXSYIBoxAPP://', '_self');
      // }
    },
    // 抽奖任务列表
    async getLotteryTaskList() {
      try {
        const query = {};
        if (this.token) {
          query.token = this.token;
        }
        const res = await this.$api.lotteryTaskList(query);
        if (res.code === 0) {
          this.lotteryTaskList = res.data;
        } else {
          return this.$toast(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 获取中奖记录
    async getLotteryUserLog() {
      if (this.luckDraw) return false;
      try {
        const query = {
          ...this.query,
          token: this.token,
        };
        const res = await this.$api.lotteryUserLog(query);
        this.showBox = 8;
        if (res.code === 0) {
          this.lotteryUserLogList = res.data;
        } else {
          return this.$toast(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 获取奖品池
    async getLotteryList() {
      try {
        const query = {};
        if (this.token) {
          query.token = this.token;
        }
        const res = await this.$api.lotteryList(query);
        console.log("获取次数", res.lotteryNum);
        if (res.code === 0) {
          res.data.forEach((e, k) => {
            this.prizes[k].imgs[0].src = e.pic;
            this.prizes[k].imgs[0].activeSrc = e.checkedPic;
          });
          this.buttons[1].fonts[0].text = `剩余抽奖机会: ${res.lotteryNum}`;
          this.lotteryNum = res.lotteryNum;
          this.lotteryList = res.data;
          this.initLoading = false;
        } else {
          this.$toast(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 获取
    async lottery() {
      return await this.$api.lottery({ token: this.token });
    },
    // 点击抽奖按钮会触发star回调
    async startCallback() {
      this.showBox = 0;
      if (!this.token) {
        this.$refs.myLucky && this.$refs.myLucky.stop();
        return this.initSdBridge({ type: -1 });
      }
      // 调用抽奖组件的play方法开始游戏
      if (this.lotteryNum <= 0) return this.$toast("抽奖机会不足");
      this.luckDraw = true;
      try {
        const res = await this.lottery();
        if (res.code === 0) {
          this.buttons[1].fonts[0].text = `剩余抽奖机会: ${
            this.lotteryNum - 1
          }`;
          this.$refs.myLucky && this.$refs.myLucky.play();
          const data = res.data;
          this.lotteryNum = res.lotteryNum;
          // 模拟调用接口异步抽奖
          setTimeout(() => {
            // 假设后端返回的中奖索引是0
            // const index = 0;
            const index = this.lotteryList.findIndex((e) => e.id === data.id);
            // 调用stop停止旋转并传递中奖索引
            this.$refs.myLucky && this.$refs.myLucky.stop(index);
            setTimeout(() => {
              this.buttons[1].fonts[0].text = `剩余抽奖机会: ${res.lotteryNum}`;

              this.lotteryInfo = data;
              this.showBox = data.type;
              this.luckDraw = false;
            }, 3000);
          }, 3000);
        } else {
          this.luckDraw = false;
          return this.$toast(res.msg);
        }
      } catch (error) {
        console.log(error);
        this.luckDraw = false;
      }
    },
    initSdBridge(item) {
      const host = process.env.VUE_APP_HOST;
      if (this.$route.query.platform) {
        let key = item.type;
        if (!this.token) {
          key = -1;
        }
        if (this.isAndroid) {
          if (key === 6) {
            // eslint-disable-next-line no-undef
            control.jumptoapp(
              JSON.stringify({ key: key, url: host + "/#/guessing" })
            );
          } else if (key === 7) {
            // eslint-disable-next-line no-undef
            control.jumptoapp(
              JSON.stringify({ key: key, url: host + "/#/lickTeam" })
            );
          } else if (key === 8) {
            // eslint-disable-next-line no-undef
            control.jumptoapp(
              JSON.stringify({ key: 7, url: host + "/#/jiaoziChange" })
            );
          } else {
            // eslint-disable-next-line no-undef
            control.jumptoapp(JSON.stringify({ key: key }));
          }
        } else if (this.isiOS) {
          if (key === 6) {
            this.bridge.callHandler(
              "jumptoapp",
              { key: key, url: host + "/#/guessing" },
              function (response) {
                console.log(JSON.stringify(response));
              }
            );
          } else if (key === 7) {
            this.bridge.callHandler(
              "jumptoapp",
              { key: key, url: host + "/#/lickTeam" },
              function (response) {
                console.log(JSON.stringify(response));
              }
            );
          } else if (key === 8) {
            this.bridge.callHandler(
              "jumptoapp",
              { key: 7, url: host + "/#/jiaoziChange" },
              function (response) {
                console.log(JSON.stringify(response));
              }
            );
          } else {
            this.bridge.callHandler(
              "jumptoapp",
              { key: key },
              function (response) {
                console.log(JSON.stringify(response));
              }
            );
          }
        }
      } else {
        if (!this.token) {
          return this.$router.push("/login");
        }
        switch (item.type) {
          case 0: // 去签到
            this.isShowSign = true;
            break;
          case 1: // 去购买
            this.$router.push("/category");
            break;
          case 2: // 去购买
            this.$router.push("/category");
            break;
          case 3: // 去邀请
            // this.$api
            //   .poster({ token: localStorage.getItem('token') })
            //   .then((e) => {
            //     this.posterUrl = e.data.url;
            //     this.showPoster = true;
            //   });
            // this.$router.push('/user');
            break;
          case 4: // 去合成
            this.$router.push("/merge");
            break;
          case 5: // 去交易
            this.$router.push("/category");
            break;
          case 6: // 去竞猜
            this.$router.push("/guessing?token=" + this.$route.query.token);
            break;
          case 7: // 去投票
            this.$router.push("/lickTeam?token=" + this.$route.query.token);
            break;
          case 8: // 去交子换购专区
            this.$router.push("/jiaoziChange?token=" + this.$route.query.token);
            break;
          default:
            break;
        }
      }
      this.showBox = 0;
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(100deg, #fca928 0%, #fc492e 42%, #f735a4 100%);
  overflow: auto;
  padding-bottom: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 9.2rem;
    margin: 15px auto 0;
    .left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        margin-right: 8px;
        width: 33px;
        height: 33px;
        border-radius: 50%;
        vertical-align: middle;
      }
      span {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 65px;
      height: 27px;
      border: 1px solid #ffffff;
      border-radius: 3px;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .list {
    width: 9.2rem;
    background: linear-gradient(
      180deg,
      #fc9f28 0%,
      #fff5e9 6%,
      #fff5e9 94%,
      #fc9f28 100%
    );
    border-radius: 10px;
    margin: 0 auto;
    margin-top: 20px;
    padding: 25px 15px 0;
    box-sizing: border-box;
    padding-bottom: 10px;
    .head {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .line {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 40px;
          vertical-align: middle;
        }
      }
      .middle {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #fc4c2b;
        margin: 0 10px;
      }
      .right {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        img {
          width: 20px;
        }
      }
    }
    .listItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;
      &:not(:last-child) {
        border-bottom: 1px solid rgba(224, 107, 43, 0.11);
      }
      .listLeft {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #9f6a20;
        line-height: 32px;
        flex: 1;
      }
      .listRight {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #fc4c2b;
        width: 55px;
        height: 24px;
        border: 1px solid #fc4c2b;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        &.defi {
          background: #bebcb8;
          border: 1px solid #bebcb8;
          color: #fff;
        }
      }
    }
  }
}
.box {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  .boxItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    .title {
      font-size: 17px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
    .img {
      width: 226px;
      // height: 265px;
      // background: #000000;
      // opacity: 0.5;
      border-radius: 5px;
      margin: 10px 0 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      img {
        width: 100%;
      }
      .tag {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        // background: rgba(0, 0, 0, 0.6);
      }
    }
    .blessing {
      position: relative;
      img {
        width: 100%;
      }
      .btn1 {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #fff5e9;
        width: 100px;
        height: 37px;
        border: 1px solid #ffffff;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .redEnvelopes {
      text-align: center;
      width: 80%;
      margin: 0 auto;
      position: relative;
      img {
        width: 100%;
      }
      .content {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        margin-top: -30px;
        .title {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #fa860b;
          letter-spacing: 2px;
        }
        .num {
          font-size: 30px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #f77a11;
          white-space: nowrap;
          span {
            font-size: 40px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #f77a11;
          }
        }
      }
    }
    .btn {
      width: 131px;
      height: 37px;
      background: linear-gradient(107deg, #e12422, #fca928);
      box-shadow: 0px 3px 0px 0px rgba(231, 101, 8, 0.36);
      border-radius: 20px;
      padding: 5px;
      box-sizing: border-box;
      div {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(-56deg, #fca928 0%, #fb3c53 100%);
        border-radius: 20px;
        width: 100%;
        height: 100%;
      }
    }
    .btn2 {
      position: absolute;
      left: 50%;
      top: 100%;
      transform: translateX(-50%);
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #fff5e9;
      width: 100px;
      height: 37px;
      border: 1px solid #ffffff;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .winningrecord {
      width: 80%;
      height: 350px;
      background: linear-gradient(
        180deg,
        #fc9f28 0%,
        #fff 6%,
        #fff 94%,
        #fc9f28 100%
      );
      border-radius: 5px;
      position: relative;
      padding: 15px 10px;
      display: flex;
      .winContainer {
        flex: 1;
        border: 1px solid #fc9f28;
        display: flex;
        flex-direction: column;
        padding-bottom: 5px;
        .title {
          font-size: 17px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #000000;
          text-align: center;
          margin: 13px 0 15px;
        }
        .content {
          flex: 1;
          min-height: 0;
          overflow-x: hidden;
          overflow-y: auto;
          .listItem {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 23px;
            padding: 0 15px;
            .listLeft {
              font-size: 13px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #9f6a20;
            }
            .listRight {
              font-size: 12px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #999999;
            }
          }
        }
      }
    }
    .close {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid #fff5e9;
      position: relative;
      transform: rotate(45deg);
      margin-top: 35px;
      &::after {
        width: 70%;
        height: 1px;
        background: #fff5e9;
        display: block;
        content: "";
        transform: translate(-50%, -50%);
        position: absolute;
        left: 50%;
        top: 50%;
        visibility: visible;
      }
      &::before {
        height: 70%;
        width: 1px;
        background: #fff5e9;
        display: block;
        content: "";
        transform: translate(-50%, -50%);
        position: absolute;
        left: 50%;
        top: 50%;
      }
    }
  }
}
.selectPayType {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 99999;
  .selectPayTypeBox {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
  .bg {
    width: 80%;
    height: 160px;
    background: #ffffff;
    border-radius: 5px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .content {
      font-size: 16px;
      color: #666666;
      margin: 35px 0 35px;
      text-align: center;
    }
    .btn {
      width: 60%;
      height: 35px;
      margin: 0 auto;
      background: #0754d3;
      border-radius: 3px;
    }
    .btnGroup {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px;
      div {
        background: #0754d3;
        border-radius: 3px;
        font-size: 14px;
        color: #fff;
        width: 90px;
        height: 33px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid transparent;
        &.unCompleted {
          border: 1px solid #0754d3;
          background: transparent;
          color: #0754d3;
        }
      }
    }
  }
}
.def {
  text-align: center;
  margin-top: 30px;
  p {
    font-size: 14px;
    color: #999;
  }
  img {
    width: 30% !important;
    margin-right: -15px;
  }
}
.popUp {
  width: 80%;
  border-radius: 4px;
  .header {
    font-size: 17px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #000000;
    text-align: center;
    padding: 20px 0 15px;
  }
  .content {
    margin: 15px 0;
    overflow: auto;
    margin: 0.4rem 0;
    max-height: 60vh;
    min-height: 20vh;
    padding: 0 20px;
    font-size: 16px;
  }
  .bottom {
    font-size: 17px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #0754d3;
    text-align: center;
    padding: 15px;
    border-top: 1px solid #ececec;
  }
}
.box {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    text-align: center;
    img {
      width: 75%;
    }
  }
}
</style>
<style>
.luckDraw .content img {
  max-width: 100%;
}
</style>
